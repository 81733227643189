<template>
  <div class="content">
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.store") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
          $t("cancel")
        }}</base-button>
        <base-button type="danger" @click="confirmDelete">{{
          $t("delete")
        }}</base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <div class="row justify-content-between align-items-center mb-4">
            <div class="col-12 col-md-8 col-lg-9 d-flex">
              <base-input
                type="search"
                v-model="searchedStore"
                @input="searchStore()"
                :placeholder="$t('searchStore')"   
                class="w-100 m-auto"
              >
              </base-input>
              <base-button simple type="primary" class="clean-button ml-sm-2" @click="cleanSearch()">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </div>
          <base-table
            v-if="!isSpinnerShow"
            :data="stores"
            :columns="storesColumns"
            :columnsSize="columnsSize"            
            class="col table-responsive-sm"
          >
            <template slot="columns">
              <th scope="col">{{ $t("idStore") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col" class="text-center">premium</th>
              <th scope="col"></th>
            </template>
            <template slot-scope="{ row }">
              <td @click="goToEditView(row.id)" class="col-1">{{ row.id }}</td>
              <td @click="goToEditView(row.id)">{{ row.name }}</td>
              <td @click="goToEditView(row.id)">{{ row.user.email }}</td>
              <td @change="setPremium(row)" class="text-center">
                <base-checkbox  
                v-model="row.premium">                  
                </base-checkbox>
              </td>
              <td class="col-2">
                <base-button  type="danger" class="col" @click="deleteStore(row.id)">
                  <span>Delete</span>
                </base-button>
              </td>
            </template>
          </base-table>
          <base-table
              v-else-if="stores.length === 0"
              :data="[1]"
              class="table-responsive-md"
          >
            <template slot="columns">
              <th scope="col">{{ $t("idStore") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("email") }}</th>
              <th scope="col">premium</th>
            </template>
            <template>
              <td class="col-3"></td>
              <td class="col-3"></td>
              <td class="col-3"><Spinner parentClasses="justify-content-start"></Spinner></td>
              <td class="col-3"></td>
            </template>
          </base-table>
          <template slot="footer">
            <pagination
              v-if="stores.length > 0"
              @turnPage="getItems"
              element="store"
              :perPage="pagination.perPage"
              :lastPage="pagination.lastPage"
              :totalItems="pagination.totalItems"
              :isChanging="pagination.isChanging"
              :currentPage="currentPage"
            ></pagination>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseInput, BaseTable, Card, Modal, Pagination, Spinner } from "@/components/index";
import { mapState } from "vuex";
import { Popover } from "element-ui";

export default {
  name: "StoreList",
  data() {
    return {
      route: "/stores",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      stores: [],
      storesFiltered: [],
      storesColumns: ["id ", "name", "email", "actions"],
      columnsSize: [null, null, null, "50px"],
      isSpinnerShow: true,
      isPopoverVisible: false,
      isModalShow: false,
      currentPage: 1,
      searchedStore: null,
      searchTimer: null,
      resetButtonsDisabled: [],
      storeId: null
    };
  },
  computed: {
    ...mapState(["isAdmin", "defaultTitle"]),
  },
  methods: {
    async getItems(page = 1) {
      this.currentPage = page;
      this.pagination.isChanging = true;
      const route = `${this.route}?page=${this.currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.stores;        
        this.stores = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.stores.length === 0;
      }
      this.pagination.isChanging = false;
    },
    searchStore() {
      if(this.searchTimer) {
        clearTimeout( this.searchTimer)
        this.searchTimer = null
      }
      if(this.searchedStore.length > 0){
        this.searchTimer = setTimeout( async()=>{
          this.currentPage = 1;
          
          let response = await this.axios.get(`${this.route}?query=${this.searchedStore}&page=${this.currentPage}`);
          if (response && response.data.status === "success") {
            const data = response.data.data.stores;
            this.stores = data.data;
            this.pagination.perPage = data.per_page;
            this.pagination.lastPage = data.last_page;
            this.pagination.totalItems = data.total;
            this.isSpinnerShow = false;
          }
        }, 500)
      }
      if(this.searchedStore.length === 0){
        this.searchedStore = null;
        this.getItems()
      }
    },
    async confirmDelete() {
      const route = `${this.route}/${this.storeId}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        console.log(response.data.status);
        this.$toast.success(this.$t("notifications.success.general"));
        this.getItems()
      }
    },
    deleteStore(storeId) {
      this.storeId = storeId;
      this.isModalShow = true;
    },
    async setPremium(store) {   
      const checkPremium = !store.premium;      
      const route = `${this.route}/${store.id}`;
      const storeFormData = new FormData();      
      storeFormData.append("premium", 1);   
      storeFormData.append("_method", "PUT");
      let response = await this.axios.post(route, storeFormData);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));                
      }else{
        store.premium = checkPremium;
      }
    },
    cleanSearch() {
      if(this.searchedStore !== null){
        this.searchedStore = null;
        this.getItems();
      }
    },
    goToEditView(itemId) {      
      this.$router.push({
        name: "storeManagement",
        params: { id: itemId },
      });
    },
  },
  created() {
    if (!this.isAdmin) this.$router.push("/");
  },
  mounted() {
    this.getItems();
  },
  components: {
    BaseInput,
    BaseTable,
    Card,
    Modal,
    Pagination,
    Popover,
    Spinner
  },
};
</script>

<style>
.minHeight {
  min-height: 90vh !important;
}
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 50%);
}
</style>
